import React, { useState, useEffect, useRef } from "react";
import { Map, MapControl, Marker, ControlPosition, AdvancedMarker } from "@vis.gl/react-google-maps";
import ClusterComponent from "./ClusterComponent";
import PoiComponent from "./PoiComponent";
import CircularGeofences from "./CircularGeofences";
import PolygonalGeofences from "./PolygonalGeofences";
import LinearGeofences from "./LinearGeofences";
import { useUserPreferences } from "../../contexts/UserPreferencesContext";
import { Polyline } from "./Polyline";
import "./Map.scss";
import Icon from "../Icon";
import { Button } from "rsuite";
import { PlaceAutocompleteClassic } from "./AutocompleteClasic";
import MapHandler from "./MapHandler";
import Directions from "./Directions";
import { Circle } from "./Circle";
import { Polygon } from "./Polygon";
import { Checkbox, Form, Input } from "antd";
import { useDevices } from "../../contexts/GpsDeviceContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag, faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { createCircularGeofence, createLinearGeofence, createMarker, createPolygonalGeofence } from "../../features/map/Map";
import { useAlert } from "../../contexts/AlertContext";

const MapComponent = () => {
  const { 
    show_pois, 
    show_circular_geofences, 
    show_polygonal_geofences, 
    show_linear_geofences, 
    map_style, 
    measuring_tool_active, 
    setMeasuringToolActive,
    search_places_active,
    new_route_active,
    setNewRouteActive,
    setShowNewCircleGeofence,
    show_new_circle_geofence,
    show_new_polygonal_geofence, // Nuevo estado para geocerca poligonal
    setShowNewPolygonalGeofence,
    addCircularGeofence,
    addPolygonalGeofence,
    new_point,
    addNewPoint,
    setNewPoint,

  } = useUserPreferences();

  const [points, setPoints] = useState([]);
  const [totalDistance, setTotalDistance] = useState(0);
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
  const [origin, setOrigin] = useState<google.maps.LatLngAltitudeLiteral | null>(null);
  const [destination, setDestination] = useState<google.maps.LatLngAltitudeLiteral | null>(null);
  const { devices } = useDevices();
  const [poi, setPoi] = useState<google.maps.LatLngAltitudeLiteral | null>(null);
  
  // Estado para la nueva geocerca circular
  const [circleCenter, setCircleCenter] = useState<google.maps.LatLngLiteral | null>(null);
  const [circleRadius, setCircleRadius] = useState(0);

  // Estado para la nueva geocerca poligonal
  const [polygonPoints, setPolygonPoints] = useState([]); // Almacena los puntos del polígono
  const lastPointRef = useRef<HTMLDivElement | null>(null);  // Ref para el último punto
  const [circular_form] = Form.useForm();
  const [polygonal_form] = Form.useForm();
  const [poi_form] = Form.useForm();
  const [new_route_form] = Form.useForm();
  const [savingCircularGeofence, setSavingCircularGeofence] = useState(false);
  const [savingPolygonalGeofence, setSavingPolygonalGeofence] = useState(false);
  const [savingPoi, setSavingPoi] = useState(false);
  const [savingNewRoute, setSavingNewRoute] = useState(false);
  const [new_route_coordinates, setNewRouteCoordinates] = useState<google.maps.LatLng[]>([]);
  const { showAlert } = useAlert();

  const mapStyle = {
    dark: "49ae42fed52588c3",
    light: "312313",
    standard: "534543",
    silver: "123134",
    retro: "54521",
    night: "342566",
    aubergine: "123213",
  };

  // Calcular la distancia entre dos puntos
  const calculateDistance = (point1, point2) => {
    const R = 6371; // Radio de la Tierra en km
    const dLat = (point2.lat - point1.lat) * (Math.PI / 180);
    const dLng = (point2.lng - point1.lng) * (Math.PI / 180);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(point1.lat * (Math.PI / 180)) *
      Math.cos(point2.lat * (Math.PI / 180)) *
      Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distancia en km
  };
  
  // Manejar los clics en el mapa para la herramienta de ruta, geocercas circulares y poligonales
  const handleMapClick = (event) => {
    const { latLng } = event.detail;
    const lat = latLng.lat;
    const lng = latLng.lng;

    if (new_point) {
      setPoi(latLng);
    }

    if (measuring_tool_active){
      // Lógica de la herramienta de medición
      const newPoint = { lat, lng };

      if (points.length > 0) {
        const lastPoint = points[points.length - 1];
        const newDistance = calculateDistance(lastPoint, newPoint);
        setTotalDistance(prev => prev + newDistance);
      }
      setPoints([...points, newPoint]);  // Agregar el nuevo punto
    }

    if (new_route_active) {
      // Lógica para la nueva ruta
      if (!origin) {
        setOrigin(latLng);
      } else if (!destination) {
        setDestination(latLng);
      } else {
        setOrigin(latLng);
        setDestination(null);
      }
    }

    if (show_new_circle_geofence) {
      if (!circleCenter) {
        // Establecer el centro de la geocerca con el primer clic
        setCircleCenter({ lat, lng });
      } else {
        // Con el segundo clic, calcular el radio basado en la distancia desde el centro
        const newRadius = calculateDistance(circleCenter, { lat, lng }) * 1000; // Convertir a metros
        setCircleRadius(newRadius);
      }
    }

    if (show_new_polygonal_geofence) {
      // Agregar los puntos del polígono
      const newPoint = { lat, lng };
      setPolygonPoints([...polygonPoints, newPoint]);
    }
  };

  // Reiniciar los puntos y distancia cuando se activa/desactiva la herramienta
  useEffect(() => {
    if (!measuring_tool_active) {
      setPoints([]);
      setTotalDistance(0);
    }
  }, [measuring_tool_active]);

  useEffect(() => {
    if (!new_route_active) {
      setOrigin(null);
      setDestination(null);
    }
  }, [new_route_active]);

  // Limpiar la geocerca cuando se desactiva la herramienta
  useEffect(() => {
    if (!show_new_circle_geofence) {
      setCircleCenter(null);
      setCircleRadius(0);
    }
  }, [show_new_circle_geofence]);

  // Limpiar los puntos del polígono cuando se desactiva la herramienta
  useEffect(() => {
    if (!show_new_polygonal_geofence) {
      setPolygonPoints([]);
    }
  }, [show_new_polygonal_geofence]);

   // Scroll al último punto cuando se agrega un nuevo punto
   useEffect(() => {
    if (lastPointRef.current) {
      lastPointRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [points]);  // Cada vez que cambien los puntos, hace scroll al último


  const handleSaveCircularGeofence = async (values) => {
    setSavingCircularGeofence(true);
    const data = {
      ...values,
      latitude: circleCenter.lat,
      longitude: circleCenter.lng,
    };
    createCircularGeofence({circular_geofence: data}).then(resp => {
      if (resp.success) {
        setShowNewCircleGeofence(false);
        setCircleCenter(null);
        setCircleRadius(0);
        circular_form.resetFields();

        if (show_circular_geofences) {
          addCircularGeofence(resp.circular_geofence);
        }

        showAlert('success', 'Geocerca circular creada correctamente');
      }else {
        showAlert('error', 'Ocurrió un error al crear la geocerca circular, vuelva a intentarlo');
      }
    }).catch(err => {
      showAlert('error', 'Ocurrió un error al crear la geocerca circular, error: ' + err.error);
    }).finally(() => {
      setSavingCircularGeofence(false);
    });
  }

  const handleSavePolygonalGeofence = async (values) => {
    setSavingPolygonalGeofence(true);
    const data = {
      ...values,
      points: polygonPoints.map(point => ({ lat: point.lat, lng: point.lng }))
    };
    
    createPolygonalGeofence({geofence: data}).then(resp => {
      if (resp.success) {
        setShowNewPolygonalGeofence(false);
        setPolygonPoints([]);
        polygonal_form.resetFields();

        if (show_polygonal_geofences) {
          addPolygonalGeofence(resp.geofence);
        }

        showAlert('success', 'Geocerca poligonal creada correctamente');
      } else {
        showAlert('error', 'Ocurrió un error al crear la geocerca poligonal, vuelva a intentarlo');
      }
    }).catch(err => {
      showAlert('error', 'Ocurrió un error al crear la geocerca poligonal, error: ' + err.error);
    }).finally(() => {
      setSavingPolygonalGeofence(false);
    });

  }

  const handleSavePoi = async (values) => {
    setSavingPoi(true);
    const data = {
      ...values,
      latitude: poi.lat,
      longitude: poi.lng,
    };

    createMarker({marker: data}).then(resp => {
      if (resp.success) {
        showAlert('success', 'Punto de interés creado correctamente');
        addNewPoint(data);
        setNewPoint(false);
        poi_form.resetFields();
      } else {
        showAlert('error', 'Ocurrió un error al crear el punto de interés, vuelva a intentarlo');
      }
    }).catch(err => {
      showAlert('error', 'Ocurrió un error al crear el punto de interés, error: ' + err.error);
    }).finally(() => {
      setSavingPoi(false);
    });

  }
  
  const handleSaveNewRoute = async (values) => {
    setSavingNewRoute(true);
    const data = {
      ...values,
      points: new_route_coordinates.reduce((obj, coord, index) => {
        obj[index] = {
            lat: coord.lat(),
            lng: coord.lng(),
        };
        return obj;
    }, {}),
    };

    // Lógica para guardar la nueva ruta
    
    createLinearGeofence({georoute: data}).then(resp => {
      if (resp.success) {
        setNewRouteActive(false);
        setOrigin(null);
        setDestination(null);
        new_route_form.resetFields();
        showAlert('success', 'Georuta creada correctamente');
      } else {
        showAlert('error', 'Ocurrió un error al crear la georuta, vuelva a intentarlo');
      }
    }).catch(err => {
      showAlert('error', 'Ocurrió un error al crear la georuta, error: ' + err.error);
    }).finally(() => {
      setSavingNewRoute(false);
    });
  }

  return (
    <div className="map-container">
      <Map
        mapId={mapStyle[map_style] || "dark"}
        defaultCenter={{ lat: 8.7768819, lng: -100.0374135 }}
        defaultZoom={4}
        gestureHandling={"greedy"}
        maxZoom={21}
        minZoom={2}
        disableDefaultUI={false}
        onClick={handleMapClick}
        fullscreenControl={false}
        mapTypeControlOptions={{
          mapTypeIds: ["roadmap", "satellite"],
          position: ControlPosition.BOTTOM_CENTER,
        }}
        zoomControlOptions={{
          position: ControlPosition.RIGHT_CENTER,
        }}
        streetViewControlOptions={{
            position: ControlPosition.RIGHT_CENTER,
        }}
      >
        <>
          {
            devices.filter(device => device.quickViewActive).map(device => (
              <div key={device.imei}>
                {device.quickViewCoordinates.length > 1 && (
                  <Polyline
                    key={device.imei}
                    path={device.quickViewCoordinates}
                    strokeColor={device.quickViewColor}
                    strokeOpacity={0.8}
                    strokeWeight={4}
                  />
                )}
                {device.quickViewCoordinates.length > 0 && (
                  device.quickViewCoordinates.map((point, index) => (
                    index === 0 ? (
                      <AdvancedMarker
                        key={index}
                        position={device.quickViewCoordinates[0]}
                      >
                        <FontAwesomeIcon icon={faFlag} style={{ color: "red", fontSize: 30 }} />
                      </AdvancedMarker>
                    ) : (
                      <AdvancedMarker
                        key={index}
                        position={point}
                        style={{ transform: `translate(-50%, -50%) translateY(-35%)` }}
                      >
                        <Icon icon="direction" size={15} fill={device.quickViewColor} color={device.quickViewColor} style={{ transform: `rotate(${device.quickViewCoordinates[index - 1].orientation - 90}deg)` }} />
                      </AdvancedMarker>
                    )
                  ))
                )}
              </div>
            ))
          }


          {measuring_tool_active && (
            <>
              {points.map((point, index) => (
                <Marker key={index} position={{ lat: point.lat, lng: point.lng }} />
              ))}
              {points.length > 1 && (
                <Polyline
                  path={points}
                  strokeColor="#FF0000"
                  strokeOpacity={0.8}
                  strokeWeight={2}
                />
              )}
            </>
          )}

          {new_route_active && origin && destination && (
            <Directions
              origin={origin}
              destination={destination}
              onDirection={(coordinates) => setNewRouteCoordinates(coordinates)}
            />
          )}

          {show_new_circle_geofence && circleCenter && (
            <Circle
              draggable={true}
              center={circleCenter}
              radius={circleRadius}
              editable={true}
              strokeColor="#FF0000"
              strokeOpacity={0.8}
              strokeWeight={2}
              fillColor="#FF0000"
              fillOpacity={0.35}
              onRadiusChanged={(radius) => {
                  setCircleRadius(radius)
                  circular_form.setFieldsValue({ radius });
              }}
              onCenterChanged={(center) => setCircleCenter({ lat: center.lat(), lng: center.lng() })}
              onDrag={(event) => {
                setCircleCenter({ lat: event.latLng.lat(), lng: event.latLng.lng() });
              }}
            />
          )}

          {new_point &&  poi && (
            <AdvancedMarker
              position={{ lat: poi.lat, lng: poi.lng }}
            />
          )}

          {/* Geocerca poligonal */}
          {show_new_polygonal_geofence && polygonPoints.length > 0 && (
            <Polygon
              paths={polygonPoints}
              strokeColor="#FF0000"
              strokeOpacity={0.8}
              strokeWeight={2}
              fillColor="#FF0000"
              fillOpacity={0.35}
              editable={true}
              onVerticesChanged={(updatedPolygon) => {
                // console.log({updatedPolygon});
                setPolygonPoints(updatedPolygon);
              }}
            />
          )}

          <MapHandler place={selectedPlace} />

          <ClusterComponent />
          {show_pois && <PoiComponent />}
          {show_circular_geofences && <CircularGeofences />}
          {show_polygonal_geofences && <PolygonalGeofences />}
          {show_linear_geofences && <LinearGeofences />}
        </>
      </Map>

      {search_places_active && (
        <MapControl position={ControlPosition.TOP_RIGHT}>
          <PlaceAutocompleteClassic onPlaceSelect={setSelectedPlace} />
        </MapControl>
      )}

      {new_route_active && (
        <div className="map-tool-container">
          <div className="header">
            <Button onClick={() => {
              setNewRouteActive(false);
              setOrigin(null);
              setDestination(null);
            }} appearance="subtle">
              <Icon icon="exit" size={10} />
            </Button>
          </div>
          <div className="content">
            <span className="title">Nueva ruta</span>
            <span>Seleccion 2 puntos en el mapa para sugerirte una ruta</span>
            <div className="horizontal-divider" />
            <Form
              layout="vertical"
              style={{ width: '100%' }}
              form={new_route_form}
              onFinish={handleSaveNewRoute}
            >
              <Form.Item
                style={{ marginBottom: 5 }}
                label="Nombre"
                name="name"
                rules={[{ required: true, message: 'Por favor ingresa un nombre' }]}>
                  <Input />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 5 }}
                name="shared"
                valuePropName="checked"
              >
                <Checkbox>Compartida</Checkbox>
              </Form.Item>
            </Form>
            <div className="horizontal-divider" />
            {<p><span className="text-bold">Origen:</span> {origin?.lat}, {origin?.lng}</p>}
            {<p><span className="text-bold">Destino:</span> {destination?.lat}, {destination?.lng}</p>}
          </div>
          <div className="horizontal-divider" />
          <Button 
            appearance="primary" 
            className="text-bold"
            disabled={!origin || !destination || savingNewRoute}
            onClick={() => {new_route_form.submit()}}
          >
              Guardar georuta
          </Button>
        </div>
      )}

      {measuring_tool_active && (
        <div className="map-tool-container">
          <div className="header">
            <Button onClick={() => {
              setMeasuringToolActive(false);
              setPoints([]);
            }} appearance="subtle">
              <Icon icon="exit" size={10} />
            </Button>
          </div>
          <div className="content">
            <span className="title">Regla</span>
            <span>Selecciona 2 o más puntos en el mapa para medir la distancia entre ellos</span>
          </div>
          <div className="horizontal-divider" />
          <div className="measuring-points">
            {points.map((point, index) => (
              <div
                className="flex-row align-center mb-1"
                key={index}
                ref={index === points.length - 1 ? lastPointRef : null}  // Añadir ref al último punto
              >
                <span>{index + 1}</span>
                <img src="assets/images/poi_marker.png" style={{ width: '13px', height: '24px', padding: "0 10px" }} alt="marker" />
                <p>{point.lat}, {point.lng}</p>
              </div>
            ))}
          </div>
          <div className="horizontal-divider" />
          <p><span className="text-bold">Distancia:</span> {totalDistance.toFixed(2)} km</p>
        </div>
      )}

      {show_new_circle_geofence  && (
        <div className="map-tool-container">
          <div className="header">
            <Button onClick={() => {
              setShowNewCircleGeofence(false);
              setCircleCenter(null);
              setCircleRadius(0);
            }}>
              <Icon icon="exit" size={10} />
            </Button>
          </div>
          <div className="content">
            <span className="title">Nueva geocerca circular</span>
            <p>Selecciona 2 puntos en el mapa para dibujar un circulo</p>
            <p>El primer clic establece el centro y el segundo clic establece el radio</p>
            <p>Puedes editar el tamaño del circulo arrastrando los puntos</p>
            <div className="horizontal-divider" />
            <Form
              layout="vertical"
              style={{ width: '100%' }}
              form={circular_form}
              onFinish={handleSaveCircularGeofence}
            >
              <Form.Item 
                style={{ marginBottom: 5 }}
                label="Nombre de la geocerca"
                name="name"
                rules={[{ required: true, message: 'Por favor ingresa un nombre' }]}>
                  <Input />
              </Form.Item>
              <Form.Item
                label="Radio"
                name="radius"
                style={{ marginBottom: 5 }}
              >
                  <Input placeholder="Radio" value={circleRadius} disabled />
              </Form.Item>
              <Form.Item
                name="risk_zone"
                style={{ marginBottom: 5 }}
                valuePropName="checked"
              >
                <Checkbox>Zona de riesgo</Checkbox>
              </Form.Item>
              <Form.Item
                name="shared"
                style={{ marginBottom: 5 }}
                valuePropName="checked"
              >
                <Checkbox>Geocerca compartida</Checkbox>
              </Form.Item>
            </Form>
            {/* <p><b>Centro:</b> {circleCenter.lat}, {circleCenter.lng}</p> */}
          </div>
          <div className="horizontal-divider" />
          <Button 
            appearance="primary" 
            className="text-bold"
            disabled={!circleRadius || savingCircularGeofence} 
            onClick={() => {
              circular_form.submit();
            }}
          >
            Guardar geocerca
          </Button>
        </div>
      )}

      {show_new_polygonal_geofence && (
        <div className="map-tool-container">
          <div className="header">
            <Button onClick={() => {
              setShowNewPolygonalGeofence(false);
              setPolygonPoints([]);
            }}>
              <Icon icon="exit" size={10} />
            </Button>
          </div>
          <div className="content">
            <span className="title">Nueva geocerca poligonal</span>
            <p>Selecciona varios puntos en el mapa para dibujar un polígono</p>
            <p>Puedes editar el polígono arrastrando los vértices</p>
            <div className="horizontal-divider" />
            <Form 
              layout="vertical" 
              style={{ width: '100%' }}
              form={polygonal_form}
              onFinish={handleSavePolygonalGeofence}
            >
              <Form.Item 
                style={{ marginBottom: 5 }}
                label="Nombre de la geocerca"
                name="name"
                rules={[{ required: true, message: 'Por favor ingresa un nombre' }]}>
                  <Input />
              </Form.Item>
              <Form.Item
                name="risk_zone"
                style={{ marginBottom: 5 }}
                valuePropName="checked"
              >
                <Checkbox>Zona de riesgo</Checkbox>
              </Form.Item>
              <Form.Item
                name="shared"
                style={{ marginBottom: 5 }}
                valuePropName="checked"
              >
                <Checkbox>Geocerca compartida</Checkbox>
              </Form.Item>
            </Form>

          </div>
          <div className="horizontal-divider" />
          <Button 
            appearance="primary" 
            className="text-bold"
            disabled={polygonPoints.length < 3 || savingPolygonalGeofence} 
            onClick={() => {
              polygonal_form.submit();
            }}
          >
            Guardar geocerca
          </Button>
        </div>
      )}

      {new_point && (
        <div className="map-tool-container">
          <div className="header">
            <Button onClick={() => setNewPoint(false)}>
              <Icon icon="exit" size={10} />
            </Button>
          </div>
          <div className="content">
            <span className="title">Nuevo punto de interés</span>
            <p>Selecciona un punto en el mapa para agregar un nuevo punto de interés que sera utilizado como punto de referencia</p>
            <div className="horizontal-divider" />

            <Form 
              layout="vertical" 
              style={{ width: '100%' }}
              form={poi_form}
              onFinish={handleSavePoi}
            >
              <Form.Item
                style={{ marginBottom: 5 }}
                label="Nombre del punto de interés"
                name="name"
                rules={[{ required: true, message: 'Por favor ingresa un nombre' }]}>
                  <Input />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 5 }}
                label="Compartido"
                name="shared"
                valuePropName="checked"
              >
                <Checkbox>Compartido</Checkbox>
              </Form.Item>
            </Form>

            {poi && <p><span className="text-bold">Coordenadas:</span> {poi.lat}, {poi.lng}</p>}
          </div>
          <div className="horizontal-divider" />
          <Button 
            appearance="primary" 
            disabled={!poi || savingPoi} 
            onClick={() => {
              poi_form.submit();
            }}
            className="text-bold"
          >
            Guardar punto de interés
          </Button>
        </div>
      )}

      

    </div>
  );
};

export default MapComponent;
